/**
 * DataGridTable
 * A wrapper around the DataTables jQuery library.
 */
(function ($, Cookies, SessionManager) {
    /**
     * Create an instance of a DataTable. Defaults can be
     * overwritten by passing your own options object with
     * the properties to overwrite.
     *
     * @param {Object} options - Any set of options that DataTable uses
     * @returns {Object} - The DataTable instance
     */
    $.fn.DataGridTable = function (options, columns) {
        let $table = $(this);
        let cookiePath = window.location.href;
        let cookieExpiry = 2 / 24;

        var initializeDataTable = function ($table, options, columns) {
            let tableId = $table.attr('id');
            let columnDefs = [];

            // Resize columns with buttons
            $table.find('tr:first-child td').each(function (i) {
                if ($(this).find('.btn').length > 0) {
                    columnDefs.push({
                        width: '10px',
                        targets: i
                    });
                }
            });

            let defaults = {
                bAutoWidth: false,
                columnDefs: columnDefs,
                initComplete: function (settings, json) {
                    $table.removeClass('my_soda_table_hidden');
                    $table.find('tbody').fadeIn();
                    $table.closest('.soda-grid-container').find('.list-view-toogle').show();
                    $table.closest('.soda-grid-container').find('.soda-spinner').remove();

                    if (options.treegrid && 'treeColumn' in options.treegrid) {
                        $table.treegrid(options.treegrid);
                        setTreeInitialState(tableId);
                    }

                    if (columns) {
                        new ColumnSettingsDropdown(tableId, columns);
                    }

                    // Do the filtering last. It should operate after the tree is initialized.
                    setFilterInitialState(tableId);
                },
                language: {
                    search: '_INPUT_',
                    searchPlaceholder: 'Search...'
                },
                lengthChange: false,
                lengthMenu: [
                    [25, 100, 250],
                    [25, 100, 250],
                ],
                paging: false,
                order: []
            };

            if ($.fn.DataTable.isDataTable($table)) {
                console.warn('DataGridTable already exists: ' + tableId);
                return $table.DataTable();
            } else {
                let mergedOptions = $.extend({}, defaults, options);
                return $table.DataTable(mergedOptions);
            }
        };

        var setTreeInitialState = function (tableId) {
            let cookieKey = tableId + '_tree';
            let storedValue = Cookies.get(cookieKey);
            let storedData = storedValue ? JSON.parse(storedValue) : {};

            if (storedData) {
                for (var key in storedData) {
                    if (!key) {
                        continue;
                    }

                    if (!storedData[key].expanded) {
                        // Default is expand - collapse if collapsed previously
                        $table.find('[data-key="' + key + '"]').find('.treegrid-expander').click();
                    }
                }
            }

            $table.find('.treegrid-expanded .treegrid-expander').on('click', function () {
                handleTreegridChange($(this));
            });

            $table.find('.treegrid-collapsed .treegrid-expander').on('click', function () {
                handleTreegridChange($(this));
            });

            function handleTreegridChange($toggle) {
                let $tr = $toggle.closest('tr');
                let key = $tr.attr('data-key');

                if (key) {
                    storedData[key] = storedData[key] || {};
                    storedData[key].expanded = $tr.hasClass('treegrid-expanded');

                    Cookies.set(cookieKey, storedData, {
                        expires: cookieExpiry,
                        path: cookiePath
                    });
                }
            }
        }

        var setFilterInitialState = function (tableId) {
            var key = tableId + '_search';

            var $filter = $('#' + tableId + '_filter');
            if ($filter.length) {
                var $input = $filter.find('input');
                $input.attr('placeholder', 'Search');
                $filter.find('label').get(0).firstChild.nodeValue = "";

                var storedData = sessionStorage.getItem(key);
                if (storedData) {
                    $input.val(storedData).trigger('input');
                }

                $input.on('input', function () {
                    sessionStorage.setItem(key, $(this).val());
                });
            }
        };

        return initializeDataTable($table, options, columns);
    };
})(jQuery, Cookies, SessionManager);
