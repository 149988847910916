// Generate a server side paginated DataTable from fields
var ServersideDataGridTable = (function ($, site, ColumnSettingsDropdown, Utils) {

    var ServersideDataGridTable = function (fields, data, options) {
        var self = this;
        var defaults = {
            ajax: function () {},
            dataTable: {},
            containerClass: '',
            columnDisplayMode: ColumnSettingsDropdown.COLUMN_DISPLAY_MODE_AUTO,
            resetSearch: false,
            rowClickable: null,
            tableSortable: false,
            tableClass: '',
            tableAttrs: {
                id: null
            },
            target: null
        };

        options = $.extend({}, defaults, options);

        var columnData = self.getColumnDataFromFields(fields, options.overrideOrder);
        var columns = columnData.columns;

        var dataTableOptions = {
            autoWidth: false,
            columns: columns,
            createdRow: function (row, rowData, rowIndex) {
                // Add unique id on row for sortable
                $(row).attr('data-id', rowData._id);

                // Add row-muted styling
                if (options.muteRow && options.muteRow(rowData)) {
                    $(row).addClass('table-row-muted');
                }

                // Add a string with any class(es) to the row
                if (options.addClassesToRow) {
                    const className = options.addClassesToRow(rowData);
                    if (className) {
                        $(row).addClass(className)
                    }
                }
            },
            dom: '<"dgt__header"<"dgt__header--left"l><"dgt__header--right"f<"dgt__column_settings">>>rtip',
            language: {
                processing: site.getDataTableSpinnerHtml()
            },
            order: columnData.order,
            lengthChange: true,
            lengthMenu: [
                [25, 100, 250],
                [25, 100, 250],
            ],
            pageLength: 100,
            paging: true,
            processing: true,
            searchDelay: 700,
            serverSide: true,
            ajax: function (dtData, callback, settings) {
                site.disableButtons();

                options.ajax(dtData).then(function (res) {
                    self.$modal.find('button').attr('disabled');
                    if (res.success) {
                        self.onAjaxSuccess(res, dtData, callback, settings, options);
                    } else {
                        return Promise.reject({ ...res, handleError: true});
                    }
                }).catch(res => {
                    return { ...res, handleError: true }
                }).always(function (res) {
                    if (res?.handleError) {
                        const errMsgText = res.error_message ?? res.message ?? "There was an error communicating with the backend.";
                        const errMsgHtml = `<span id='ajax-error' class='text-danger'>${errMsgText}</span>`;
                        //Try to replace the spinner with the error message
                        self.$modal.find('.dataTables-spinner').parent().html(errMsgHtml);
                        //Log to console just in case anyway
                        console.warn(errMsgText);
                    }
                    site.enableButtons();
                    site.tooltip();
                });
            }
        };

        this.dataTableOptions = dataTableOptions = $.extend({}, dataTableOptions, options.dataTable);

        this.$root = $(options.target).first();
        this.$modal = this.$root.closest('.modal.in');
        this.tableId = options.tableAttrs.id;
        this.data = data;
        this.fields = fields;
        this.columns = columns;
        this.options = options;
        this.initialize();
    };

    ServersideDataGridTable.prototype.setFilterInitialState = function (tableId) {
        var key = tableId + '_search';

        var $filter = $('#' + tableId + '_filter');
        if ($filter.length) {
            var $input = $filter.find('input');
            $input.attr('placeholder', 'Search');
            $filter.find('label').get(0).firstChild.nodeValue = "";

            var storedData = sessionStorage.getItem(key);
            if (storedData) {
                $input.val(storedData).trigger('input');
            }

            $input.on('input', function () {
                sessionStorage.setItem(key, $(this).val());
            });
        }
    };


    ServersideDataGridTable.prototype.getColumnDataFromFields = function (fields, overrideOrder = null) {
        var columns = [];
        var order = [];

        // Sort the columms based on priority (and column id, if the priority is the same)
        fields.sort(function (a, b) {
            if (a.priority === b.priority) {
                return a.columnId - b.columnId;
            } else {
                return a.priority - b.priority;
            }
        });

        fields.forEach(function (field, i) {
            if (overrideOrder === null) {
                switch (field.type) {
                    case 'rank':
                        order.push([i, 'asc']);
                        break;
                }
            }

            var newColumn = {
                label: field.value.EN,
                data: field.key,
                render: field.render,
                // The searchable flag here doesn't appear to have any effect.
                searchable: 'sortable' in field ? !!field.sortable : true,
                // This is intentional (orderable vs sortable)
                orderable: 'sortable' in field ? !!field.sortable : true,
                width: 'width' in field ? field.width : null,
                customData: {
                    priority: field.priority,
                    visible: field.visible
                }
            };

            columns.push(newColumn);
        });

        return {columns: columns, order: order};
    };

    ServersideDataGridTable.prototype.initialize = function () {
        this.render();
    };

    ServersideDataGridTable.prototype.onAjaxSuccess = function (response, dtData, callback, settings, options) {
        var self = this;
        var data = response.data;

        var items = [];

        data.items.forEach(function (item) {
            var newItem = {};
            self.fields.forEach(function (field) {
                switch (field.type) {
                    case 'rank':
                        newItem[field.key] = (!options.hideRankdraggerForUnrankedItems || item.rank) ?
                            '<span class="glyphicon glyphicon-move rankdragger"></span>' :
                            '<span class="__unranked"></span>' ;
                        break;

                    default:
                        var value = item[field.key];
                        if (Utils.isNullorUndefined(value)) {
                            newItem[field.key] = '';
                        } else if (typeof item[field.key] === 'object') {
                            newItem[field.key] = JSON.stringify(value);
                        } else {
                            newItem[field.key] = value;
                        }
                        break;
                }
            });

            // Add in the _id key. This is necessary to identify the record
            newItem['_id'] = item['_id'];
            // Attach the original data
            newItem['__original_data'] = item;

            items.push(newItem);
        });

        callback({
            draw: data.draw,
            recordsTotal: data.records_total,
            recordsFiltered: data.records_filtered,
            data: items
        });

        // Allow reordering again
        site.subRecordsTable(self.$modal);
        site.resizeModal(self.$modal);

        if (self.columnSettingsDropdown) {
            self.columnSettingsDropdown.refresh();
        }

        // Initialize validation messages that may have been loaded into the modal.
        site.initValidationMessagesPopoversTooltips(self.$modal);
    };

    ServersideDataGridTable.prototype.render = function () {
        var self = this;

        var classes = ['dataTables-table', 'table', 'table-bordered'];
        if (self.options.rowClickable) {
            classes.push('table-tr-clickable');
        }

        if (self.options.tableSortable) {
            classes.push('table-sortable');
        }

        var html = `
        <div class="ss_dgt_wrapper table-responsive ${self.options.containerClass}">
            <table id="${self.tableId}" class="${classes.join(' ')}" ${Object.keys(self.options.tableAttrs).map((attr) => `${attr}="${self.options.tableAttrs[attr]}"`).join(' ')}>
                <thead>
                    <tr>${self.fields.map((field) => `<th>${field.value.EN}</th>`).join('')}</tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>`;

        self.$root.append(html);
        self.$root.find('#' + self.tableId).DataTable(self.dataTableOptions);

        var html = `
         <span id="${self.tableId + '_dropdown'}" class="dropdown" style="position: relative;">
             <a type="button" class="button btn btn-primary btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                 <i class="fas fa-cog" aria-hidden="true"></i>
                 <i class="fas fa-chevron-down" aria-hidden="true"></i>
             </a>
             <ul class="dropdown-menu dropdown-menu-right checklist_dropdown" aria-labelledby="dLabel">
                 <div class="dropdown-header margin-bottom-10">Choose which columns are displayed:</div>
                 <div class="dropdown-body">
                 ${self.columns.map((column) => `<li class="checkbox toggleColumnVisibility" data-id="${column.data}"><label><input type="checkbox" />${column.label}</label></li>`).join('')}
                 </div>
                 <div class="dropdown-footer">
                     <li role="separator" class="divider"></li>
                     <button type="button" class="btn btn-primary">Close</button>
                 </div>
             </ul>
         </span>
         `;

        var $columnSettingsDropdown = $(html);
        self.$root.find('.dgt__column_settings').append($columnSettingsDropdown);

        self.columnSettingsDropdown = new ColumnSettingsDropdown(self.tableId, self.columns, {
            columnDisplayMode: self.options.columnDisplayMode
        });

        // Make sure the filter is setup properly
        if (!this.options.resetSearch) {
            this.setFilterInitialState(self.tableId);
        }
    };

    return ServersideDataGridTable;
})(jQuery, site, ColumnSettingsDropdown, Utils);

window.ServersideDataGridTable = ServersideDataGridTable;
