var ModalFooter = (function () {
    var getSaveBtnHtml = function (text) {
        return '<button type="button" class="btn btn-primary btn-footeraction btn-flat" data-action="save">' + text + '</button>';
    };

    var getFooterHtml = function (viewMode, script) {
        const cancelBtn = '<button type="button" class="btn btn-default cancel-btn btn-flat" data-dismiss="modal">Cancel</button>';
        const cloneBtn = 
            '<button type="button" class="btn btn-primary btn-subaction pull-left btn-flat" data-action="clone" data-modal="1" data-id="id">' +
                (viewMode === 'edit' ? 'Clone' : 'Clone again') + 
            '</button>';
        const saveBtn = getSaveBtnHtml('Save');
        const imageHolder = '<div class="hiddenimageholder"></div>';

        let btnHtml = '';

        switch (viewMode) {
            case 'view':
                switch (script) {
                    case 'module_records_view_edit':
                        btnHtml =
                                cancelBtn +
                                saveBtn +
                                imageHolder;
                        break;

                    case 'module_records_view_sync':
                        btnHtml =
                                cancelBtn +
                                getSaveBtnHtml('Save & Sync') +
                                imageHolder;
                        break;

                    default:
                        btnHtml =
                                '<button type="button" class="btn btn-primary cancel-btn btn-flat" data-dismiss="modal">Close</button>';
                        break;
                }
                break;

            case "add":
                switch (script) {
                    case 'pushnotifications_view':
                        btnHtml =
                                cancelBtn +
                                getSaveBtnHtml('Send') +
                                imageHolder;
                        break;

                    default:
                        btnHtml =
                                cancelBtn +
                                saveBtn +
                                imageHolder;
                        break;
                }
                break;

            case 'clone':
            case 'edit':
                switch (script) {
                    case 'module_records_sub_edit':
                        btnHtml = '<button type="button" class="btn btn-danger btn-footeraction pull-left btn-flat" data-action="delete">Delete</button>' +
                                cloneBtn +
                                cancelBtn +
                                saveBtn +
                                imageHolder;
                        break;

                    case 'module_clone_other':
                        btnHtml =
                                cancelBtn +
                                getSaveBtnHtml('Confirm') +
                                imageHolder;
                        break;

                    case 'module_clone_english':
                    case 'module_types_available_all_add':
                    case 'module_types_available_latest_add':
                            btnHtml =
                                '<button type="button" class="btn btn-default btn-footeraction btn-flat" data-action="back_refresh">Close</button>';
                        break;

                    case 'module_import_csv_view_edit':
                        btnHtml =
                                cancelBtn +
                                getSaveBtnHtml('Upload') +
                                imageHolder;
                        break;

                    case 'module_records_sub_table_edit':
                    default:
                        btnHtml =
                                cancelBtn +
                                saveBtn +
                                imageHolder;
                        break;
                }
                break;
        }

        // Overrides
        switch (script) {
            case 'module_content':
                // Clear out any buttons in the module content view - this will have
                // tabs
                btnHtml = '';
                break;

            case 'know_who_categories_add_edit':
            case 'data_source_values_add_edit':
            case 'data_source_attributes_edit':
            case 'help_files_view_select':
                btnHtml =
                        cancelBtn +
                        '<button type="button" class="btn btn-primary btn-footeraction btn-flat" data-action="save_local">Save</button>' +
                        imageHolder;
                break;
        }

        return '<div class="modal-footer">' + btnHtml + '</div>';
    };

    return {
        getFooterHtml: getFooterHtml
    };
})();

window.ModalFooter = ModalFooter;
