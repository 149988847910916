var FormValidator = (function (Utils, CKEDITOR) {
    var FormValidator = function () {
        this.errors = [];
    };

    FormValidator.prototype.getErrors = function () {
        return this.errors;
    };

    FormValidator.prototype.validate = function (parentElement, subelem) {
        const coordinateRegex = /^(-?\d+(\.\d+)?)$/;
        const emailRegex = /^[^\s@;,]+@[^\s@;,]+\.[^\s@;,]+$/;
        const urlRegex =
            /^(?:(?:http[s]?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9_]-*)*[a-z\u00a1-\uffff0-9_]+)(?:\.(?:[a-z\u00a1-\uffff0-9_]-*)*[a-z\u00a1-\uffff0-9_]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/;
        const uriRegex = /^(([^:/?#]+):)?(\/\/([^/?#\s]*))?([^?#\s]*)(\?([^#\s]*))?(#(.*))?$/;
        const phoneRegex = /^(\+)?([0-9;,()\-\s]*|x|ext|extension)*$/;
        const dateRegex = /^(\d{4})\-(\d{2})\-(\d{2})$/;
        const dateTimeRegex = /^(\d{4})\-(\d{2})\-(\d{2})(T| )?(\d{2})\:(\d{2})(\:(\d{2}))?$/;
        const dateTimeTzRegex = /^(\d{4})\-(\d{2})\-(\d{2})T(\d{2})\:(\d{2})\:(\d{2})(.*)$/;
        const numberRegex = /^\d+$/;
        const booleanRegex = /^\d+$/;
        // Based on https://stackoverflow.com/questions/16242449/regex-currency-validation
        const currencyRegex = /(?=.*?\d)^[\$£€A-Z]*(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;

        parentElement.find('#adminform .form_spinner').show();
        parentElement.find('.error-label').remove();
        parentElement.find('#adminform').find('.required_error').removeClass('required_error');

        // Check for required fields
        parentElement.find('#adminform .required').each(function () {
            var $innerElement = $(this);

            if ($innerElement.is(':visible')) {
                // Refresh the data in the HTML editor
                if ($innerElement.hasClass('ckeditor')) {
                    CKEDITOR.instances[$innerElement.attr('id')].updateElement();
                }

                // Use trim(), as whitespace only does not satisfy the 'required' constraint.
                var elementValue = $innerElement.val();

                if (!elementValue) {
                    $innerElement.addClass('required_error');
                } else if (elementValue instanceof String && elementValue.trim() === '') {
                    $innerElement.addClass('required_error');
                } else if (Array.isArray(elementValue) && elementValue.length == 0) {
                    $innerElement.addClass('required_error');
                } else {
                    // Check is a non-zero element
                    switch ($innerElement.attr('data-validate')) {
                        case 'nozero':
                            if (elementValue == '0') {
                                $innerElement.addClass('required_error');
                                if ($innerElement.attr('data-visualelem') != 'undefined') {
                                    //Apply border to the label
                                    $($innerElement.attr('data-visualelem')).addClass(
                                        'required_error'
                                    );
                                }
                            }
                            break;

                        case 'multicheckbox':
                            // Get element group
                            var datagroup = $innerElement.attr('data-group');
                            if (
                                parentElement.find(
                                    "#adminform [data-group='" + datagroup + "']:checked"
                                ).length == 0
                            ) {
                                $($innerElement.attr('data-visualelem')).addClass('required_error');
                            }
                            break;
                    }
                }
            }
        });

        //
        // Check for required form groups
        //
        // If a form group is required, then at least one of the input elements in it must be set
        //
        parentElement.find('#adminform .required-form-group').each(function () {
            let $groupElement = $(this);
            let groupEmpty = true;

            $groupElement.find(':input').each(function () {
                var $inputElement = $(this);
                if ($inputElement.val().trim() != '') {
                    groupEmpty = false;
                }
            });

            if (groupEmpty) {
                $groupElement.addClass('required_error');
            }
        });

        parentElement
            .find(
                '#adminform [data-id="upload_media_container"],#adminform [data-id="upload_container"]'
            )
            .each(function () {
                // Required for plain list, profile
                Utils.clearInputs($(this));
            });

        if (parentElement.find('#adminform .required_error').length > 0) {
            subelem.removeClass('disabled');
            Utils.loader('hide');

            this.errors.push('Please fill out all fields.');
            return false;
        }

        var formErrorMsgs = [];

        // Validate all other fields based on type
        parentElement.find("#adminform [data-validate='array']").each(function (index, element) {
            // The array has a min property set - need to make sure there are enough entries.
            const minEntries = $(this).data('minEntries');
            if ($(this).find('div').length < minEntries) {
                const label = $(this).parent().find('.soda-array-group-heading').html();
                formErrorMsgs.push(`"${label}" needs a minimum of ${minEntries} entr${minEntries === 1 ? 'y' : 'ies'}`);
            }
        });

        parentElement.find("#adminform [data-validate='text']").each(function (index, element) {
            let errorMsg = baseValidate($(this), parentElement, false, true, true);

            if (errorMsg) {
                formErrorMsgs.push(errorMsg);
            }
        });

        parentElement.find("#adminform [data-validate='textarea']").each(function (index, element) {
            let errorMsg = baseValidate($(this), parentElement, false, true, true);

            if (errorMsg) {
                formErrorMsgs.push(errorMsg);
            }
        });

        parentElement.find("#adminform [data-validate='select']").each(function (index, element) {
            let errorMsg = baseValidate($(this), parentElement, false, false, false);

            if (errorMsg) {
                formErrorMsgs.push(errorMsg);
            }
        });

        parentElement
            .find("#adminform [data-validate='coordinate']")
            .each(function (index, element) {
                let errorMsg = baseValidate($(this), parentElement, true, true, true);
                let value = $(this).val();

                if (!errorMsg) {
                    if (value != '' && !coordinateRegex.test(value)) {
                        errorMsg = formaterrorMsg(
                            $(this),
                            parentElement,
                            'is not a valid coordinate. Example: 90.000000'
                        );
                    }
                }

                if (errorMsg) {
                    formErrorMsgs.push(errorMsg);
                }
            });

        parentElement.find("#adminform [data-validate='email']").each(function (index, element) {
            let errorMsg = baseValidate($(this), parentElement, true, true, true);
            let value = $(this).val();

            if (!errorMsg) {
                if (value != '' && !emailRegex.test(value)) {
                    errorMsg = formaterrorMsg(
                        $(this),
                        parentElement,
                        'is not a valid email address'
                    );
                }
            }

            if (errorMsg) {
                formErrorMsgs.push(errorMsg);
            }
        });

        parentElement.find("#adminform [data-validate='currency']").each(function (index, element) {
            let errorMsg = baseValidate($(this), parentElement, true, true, true);
            let value = $(this).val();

            if (!errorMsg) {
                if (value != '' && !currencyRegex.test(value)) {
                    errorMsg = formaterrorMsg(
                        $(this),
                        parentElement,
                        'is not a valid currency amount'
                    );
                }
            }

            if (errorMsg) {
                formErrorMsgs.push(errorMsg);
            }
        });

        parentElement
            .find("#adminform [data-validate='email_list']")
            .each(function (index, element) {
                let errorMsg = baseValidate($(this), parentElement, true, true, true);
                let value = $(this).val();

                if (!errorMsg) {
                    let updatedValue = listRegexCheck(value, emailRegex);
                    if (updatedValue !== false) {
                        $(this).val(emailListRemoveDuplicates(updatedValue));
                    } else {
                        errorMsg = formaterrorMsg(
                            $(this),
                            parentElement,
                            'is not a valid email address or list of email addresses'
                        );
                    }
                }

                if (errorMsg) {
                    formErrorMsgs.push(errorMsg);
                }
            });

        parentElement.find("#adminform [data-validate='json']").each(function (index, element) {
            let errorMsg = baseValidate($(this), parentElement, true, true, true);
            let value = $(this).val();

            if (!errorMsg) {
                try {
                    if (value) {
                        // Only do a validation parse if there is actually a value
                        JSON.parse(value);
                    }
                } catch (ex) {
                    errorMsg = formaterrorMsg($(this), parentElement, ex.message);
                }
            }

            if (errorMsg) {
                formErrorMsgs.push(errorMsg);
            }
        });

        parentElement.find("#adminform [data-validate='url']").each(function (index, element) {
            let errorMsg = baseValidate($(this), parentElement, true, true, true);
            let value = $(this).val();

            if (!errorMsg) {
                if (value != '' && !urlRegex.test(value)) {
                    errorMsg = formaterrorMsg($(this), parentElement, 'is not a valid URL.');
                }
            }

            if (errorMsg) {
                formErrorMsgs.push(errorMsg);
            }
        });

        parentElement.find("#adminform [data-validate='url_list']").each(function (index, element) {
            let errorMsg = baseValidate($(this), parentElement, true, true, true);
            let value = $(this).val();

            if (!errorMsg) {
                let updatedValue = listRegexCheck(value, urlRegex, '\n');
                if (updatedValue !== false) {
                    $(this).val(updatedValue);
                } else {
                    errorMsg = formaterrorMsg(
                        $(this),
                        parentElement,
                        'is not a valid URL or list of URLs.'
                    );
                }
            }

            if (errorMsg) {
                formErrorMsgs.push(errorMsg);
            }
        });

        parentElement.find("#adminform [data-validate='uri']").each(function (index, element) {
            let errorMsg = baseValidate($(this), parentElement, true, true, true);
            let value = $(this).val();

            if (!errorMsg) {
                if (value != '' && !uriRegex.test(value)) {
                    errorMsg = formaterrorMsg($(this), parentElement, 'is not a valid URI.');
                }
            }

            if (errorMsg) {
                formErrorMsgs.push(errorMsg);
            }
        });

        parentElement.find("#adminform [data-validate='phone']").each(function (index, element) {
            let errorMsg = baseValidate($(this), parentElement, true, true, true);
            let value = $(this).val();

            if (!errorMsg) {
                if (value != '' && !phoneRegex.test(value)) {
                    errorMsg = formaterrorMsg(
                        $(this),
                        parentElement,
                        'is not a valid phone number.'
                    );
                }
            }

            if (errorMsg) {
                formErrorMsgs.push(errorMsg);
            }
        });

        parentElement
            .find("#adminform [data-validate='phone_list']")
            .each(function (index, element) {
                let errorMsg = baseValidate($(this), parentElement, true, true, true);
                let value = $(this).val();

                if (!errorMsg) {
                    let updatedValue = listRegexCheck(value, phoneRegex);
                    if (updatedValue !== false) {
                        $(this).val(phoneListRemoveDuplicates(updatedValue));
                    } else {
                        errorMsg = formaterrorMsg(
                            $(this),
                            parentElement,
                            'is not a valid phone number or list of phone numbers.'
                        );
                    }
                }

                if (errorMsg) {
                    formErrorMsgs.push(errorMsg);
                }
            });

        parentElement.find("#adminform [data-validate='date']").each(function (index, element) {
            let errorMsg = baseValidate($(this), parentElement, true, true, true);
            let value = $(this).val();

            if (!errorMsg) {
                if (value != '' && !dateRegex.test(value)) {
                    errorMsg = formaterrorMsg(
                        $(this),
                        parentElement,
                        'is not in the expected format: yyyy-mm-dd.'
                    );
                }
            }

            if (errorMsg) {
                formErrorMsgs.push(errorMsg);
            }
        });

        parentElement.find("#adminform [data-validate='datetime']").each(function (index, element) {
            let errorMsg = baseValidate($(this), parentElement, true, true, true);
            let value = $(this).val();

            if (!errorMsg) {
                if (value != '' && !dateTimeRegex.test(value)) {
                    errorMsg = formaterrorMsg(
                        $(this),
                        parentElement,
                        'is not in the expected format: yyyy-mm-dd hh:mm:ss.'
                    );
                }
            }

            if (errorMsg) {
                formErrorMsgs.push(errorMsg);
            }
        });

        parentElement
            .find("#adminform [data-validate='datetime_tz']")
            .each(function (index, element) {
                let errorMsg = baseValidate($(this), parentElement, true, true, true);
                let value = $(this).val();

                if (!errorMsg) {
                    if (value != '' && !dateTimeTzRegex.test(value)) {
                        errorMsg = formaterrorMsg(
                            $(this),
                            parentElement,
                            'is not in the expected format: yyyy-mm-ddThh:mm:ssZ.'
                        );
                    }
                }

                if (errorMsg) {
                    formErrorMsgs.push(errorMsg);
                }
            });

        parentElement.find("#adminform [data-validate='rank']").each(function (index, element) {
            let errorMsg = baseValidate($(this), parentElement, true, true, true);
            let value = $(this).val();

            if (!errorMsg) {
                if (value != '' && parseInt(value) < 0) {
                    errorMsg = formaterrorMsg($(this), parentElement, 'must be greater than 0.');
                }
            }

            if (errorMsg) {
                formErrorMsgs.push(errorMsg);
            }
        });

        parentElement.find("#adminform [data-validate='number']").each(function (index, element) {
            let errorMsg = baseValidate($(this), parentElement, true, true, false);
            let value = $(this).val();

            if (!errorMsg) {
                if (value != '' && !numberRegex.test(parseInt(value))) {
                    errorMsg = formaterrorMsg($(this), parentElement, 'value is not a number.');
                }
            }

            if (errorMsg) {
                formErrorMsgs.push(errorMsg);
            }
        });

        parentElement.find("#adminform [data-validate='boolean']").each(function (index, element) {
            let errorMsg = baseValidate($(this), parentElement, true, true, false);
            let value = $(this).val();

            if (!errorMsg) {
                if (value != '' && !booleanRegex.test(parseInt(value))) {
                    errorMsg = formaterrorMsg($(this), parentElement, 'must be a Yes/No value.');
                }
            }

            if (errorMsg) {
                formErrorMsgs.push(errorMsg);
            }
        });

        parentElement
            .find("#adminform [data-validate='meeting_request']")
            .each(function (index, element) {
                var innerElement = $(this);

                if (innerElement.is(':checked'))
                    if (parentElement.find('#adminform #email').length == 0) {
                        innerElement.addClass('required_error');
                        formErrorMsgs.push('An email field is required for meeting requests.');
                    } else {
                        if (parentElement.find('#adminform #email').val() == '') {
                            formErrorMsgs.push('An email field is required for meeting requests.');
                            innerElement.addClass('required_error');
                        }
                    }
            });

        if (formErrorMsgs.length > 0) {
            this.errors.push(formErrorMsgs.join('<br>'));
            return false;
        } else {
            return true;
        }
    };

    function baseValidate(element, parentElement, trim, blankAllowed, maxLengthCheck) {
        let value = element.val();
        if (trim) {
            value = value.trim();
            element.val(value);
        }

        if (!blankAllowed) {
            if (value == '') {
                return formaterrorMsg(element, parent, 'is not allowed to be blank.');
            }
        }

        if (maxLengthCheck) {
            let maxLength = element.attr('maxlength') || element.attr('data-length');

            if (maxLength && value.length > maxLength) {
                return formaterrorMsg(
                    element,
                    parentElement,
                    'must be less than ' + maxLength + ' characters.'
                );
            }
        }
    }

    function formaterrorMsg(element, parentElement, errorString) {
        let name = element.attr('name');
        let labelElement = parentElement ? parentElement.find('[for="' + name + '"]') : null;
        let label = labelElement ? labelElement.html() : name;
        element.addClass('required_error');
        return '"' + label + '" ' + errorString;
    }

    function listRegexCheck(value, regex, listSeparator = ', ') {
        const splitRegex = /[,;\n]+|\r\n+/;
        let valid = true;
        let savedValues = [];
        let splitValue = value.split(splitRegex);

        for (let i = 0; i < splitValue.length; i++) {
            let currentValue = splitValue[i];

            // Ignore blank lines
            if (currentValue) {
                curentValueTrim = currentValue.trim();

                if (regex.test(curentValueTrim)) {
                    savedValues.push(curentValueTrim);
                } else {
                    valid = false;
                    break;
                }
            }
        }

        if (valid) {
            // Return back a formatted version of the string using consistent separators
            let uniqueSet = [...new Set(savedValues)];
            return uniqueSet.join(listSeparator);
        } else {
            return false;
        }
    }

    // Remove duplicate numbers that may be formatted differently.
    function phoneListRemoveDuplicates(value, listSeparator = ', ') {
        const numbers = value.split(listSeparator);

        // Make a copy stripped of non-numeric characters.
        const numbersNumeric = [...numbers];
        numbersNumeric.forEach(
            (number, index) => (numbersNumeric[index] = number.replace(/\D/g, ''))
        );

        // Get the indexes of the first occurrance of each number.
        const indexes = numbersNumeric.map((number, index) =>
            numbersNumeric.indexOf(number) === index ? index : null
        );

        // Use the indexes to pick the first occurrance of each number from the original value (with original formatting).
        const numbersUnique = [];
        indexes.forEach((index) => {
            if (index !== null) {
                numbersUnique.push(numbers[index]);
            }
        });

        return numbersUnique.join(listSeparator);
    }

    function emailListRemoveDuplicates(value, listSeparator = ', ') {
        const emails = value.split(listSeparator);
        emails.forEach((email, index) => (emails[index] = email.toLowerCase()));

        return emails.filter((email, index) => emails.indexOf(email) === index).join(listSeparator);
    }

    return FormValidator;
})(Utils, moment, CKEDITOR);

window.FormValidator = FormValidator;
